import Swiper from 'swiper';
import {
    Navigation,
    Autoplay,
    Pagination,
    Scrollbar,
    Grid
} from 'swiper/modules';





Swiper.use([Navigation, Autoplay, Pagination, Scrollbar, Grid]);
export function horscrollsw(sliders = ".swiper-scroller") {


    var scrollersBuild = (function() {

        let elements = {
            sl: sliders
        };

        var build = function(selector) {

            var arr = document.querySelectorAll(selector);
            arr.forEach(function(el, index) {
                const settings = el.dataset.settings ||
                    JSON.stringify({ slidesScreen:3, slidesDesktop: 3, slidesTablet: 2, slidesMobile: 2 });
                const dataArr = JSON.parse(settings);
                let filters = el.dataset.filterby;
                el.classList.add('swiper-slider-' + index);
                var dragSize = 50;
                var freeMode = false;
                var loop = dataArr.loop || false;
                var slidesScreen = dataArr.slidesScreen || 4;
                var slidesDesktop = dataArr.slidesDesktop || 4;
                var slidesTablet = dataArr.slidesTablet || 3;
                var slidesMobile = dataArr.slidesMobile || 2;
                var spaceBetween = 16;
                var watchOverflow = true;
                var swiper
                var createslider = function() {
                    swiper = new Swiper('.swiper-slider-' + index, {
                        autoHeight:true,
                        direction: 'horizontal',
                        loop: loop,
                        freeMode: freeMode,
                        watchOverflow: watchOverflow,
                        spaceBetween: spaceBetween,
                        breakpoints: {
                            1450: {
                                slidesPerView: slidesScreen
                            },
                            1200: {
                                slidesPerView: slidesDesktop
                            },
                            992: {
                                slidesPerView: slidesTablet
                            },
                            480: {
                                slidesPerView: slidesMobile
                            }
                        },
                        on: {
                            afterInit: function(sw) {

                            },
                            slidesLengthChange: function(sw) {

                            },
                            update: function(sw) {

                            },
                        },
                        navigation: {
                            nextEl: '.swiper-slider-' + index + ' .swiper-button-next',
                            prevEl: '.swiper-slider-' + index + ' .swiper-button-prev'
                        },
                        pagination: {
                          el: ('.swiper-slider-' + index + ' .swiper-pagination-slider'),
                          type: 'bullets',
                          clickable: true,
                        },
                        scrollbar: {
                            el: '.swiper-slider-' + index + ' .swiper-scrollbar',
                            draggable: true,
                            hide: false,
                            //dragSize: dragSize
                        }
                    });

                    if (filters) {

                        var filtList = document.getElementById(filters);
                        var parentFilt = filtList.parentNode;
                        var tabs = filtList.querySelectorAll(".nav-link");
                        var target = arr[index];

                        var swtab = new Swiper(parentFilt, {
                            slidesPerView: 'auto',
                            wrapperClass: 'tab-nav',
                            slideClass: 'nav-item',
                            paginationClickable: true,
                            spaceBetween: 0,
                            threshold: 20,
                            preventClicksPropagation: true,
                            preventClicks: true,
                            on: {
                                init: function() {
                                    let navits = filtList.querySelectorAll('.nav-link');
                                    navits.forEach(function(dot, index) {

                                        //scrolldrag(filtList);
                                        dot.addEventListener('click', (e) => {
                                            navits.forEach(it => { it.classList.remove("active"); });
                                            dot.classList.add("active");
                                            let slidesAll = target.querySelectorAll(".swiper-slide");
                                            let filtme = dot.dataset.filter;
                                            let slshow;
                                            if (filtme == "all") { slshow = slidesAll } else { slshow = target.querySelectorAll(filtme) };
                                            for (const sl of slidesAll) {
                                                sl.classList.add("d-none");
                                                sl.classList.remove("d-block");
                                            }
                                            for (const sl of slshow) {
                                                sl.classList.add("d-block");
                                                sl.classList.remove("d-none");
                                            }
                                            swiper.updateSlides();
                                            swiper.updateProgress();
                                            swiper.slideTo(0);
                                            swiper.scrollbar.updateSize();
                                            return false;
                                        });
                                    })


                                },
                            },

                        });

                    }


                }

                createslider();

            })
        };


        var buildall = function() {
            build(elements.sl);
        };

        return {
            init: function() {
                buildall();
            }
        };
    })();

    scrollersBuild.init();

}

export function navswipe(nav = ".swipe-nav") {
    if ( !window.globalNavswipes ) {
        window.globalNavswipes = [];
    }
    var arr = document.querySelectorAll(nav);
    arr.forEach(function(el, index) {
        
        var swiper = new Swiper(el, {
            slidesPerView: "auto",
            spaceBetween: 30,
            slidesPerView: 'auto',
            wrapperClass: 'tab-nav',
            slideClass: 'nav-item',
            paginationClickable: true,
            spaceBetween: 0,
            threshold: 20,
            preventClicksPropagation: true,
            preventClicks: true,
        });
        window.globalNavswipes.push(swiper);
    })
    
}

    /*-- Brand Logo --*/

export function brandCarousel(logoswiper = ".brand-logo-carousel .swiper-container") {	

        var arr = document.querySelectorAll(logoswiper);
        arr.forEach(function(el, index) {
            const settings = el.dataset.settings ||
                JSON.stringify({ slidesDesktop: 3, slidesTablet: 3, slidesMobile: 2 });
            const dataArr = JSON.parse(settings);
            let filters = el.dataset.filterby;
            el.classList.add('swiper-items-' + index);
            var newel='.swiper-items-' + index;
            var loop = dataArr.loop || false;
            var delaymsc = dataArr.delay || false;
            var slidesDesktop = dataArr.slidesDesktop || 3;
            var slidesTablet = dataArr.slidesTablet || 3;
            var slidesMobile = dataArr.slidesMobile || 2;
            
            var autoplayprm;
            if (delaymsc) {
                var autoplayprm = {
                    delay: delaymsc,
                }
            } else {autoplayprm = false}
            var dragSize = 50;
            var swiper;
            var createslider = function() {
                swiper = new Swiper('.swiper-items-' + index, {
                    loop: loop,
                    speed: 750,
                    spaceBetween: 30,
                    slidesPerView: 3,
                    //grid: {
                    //    rows: 2,
                    //    fill: "row"
                    //},
                    effect: 'slide',
                    autoplay: autoplayprm,
                    createElements:true,
                    //Responsive breakpoints
                    breakpoints: {
                        // when window width is >= 320px
                        320: {
                        slidesPerView:slidesMobile,
                        },
                        // when window width is >= 480px
                        480: {
                        slidesPerView:slidesMobile,
                        },
                        // when window width is >= 768px
                        768: {
                        slidesPerView:slidesTablet,
                        },
                        // when window width is >= 992px
                        992: {
                        slidesPerView:slidesDesktop,
                        }
                    }
                })
            }
            createslider();
        })
}
