import Masonry from 'masonry-layout/dist/masonry.pkgd'
//-------------------------------------//

export function masGrid() {
  let grids = document.querySelectorAll('.gridmas');
  grids.forEach((item,index) => {
    
  let msnry = new Masonry( item, {
    itemSelector: '.grid__item', // select none at first
    columnWidth: '.grid__col-sizer',
    gutter: '.grid__gutter-sizer',
    percentPosition: true,
    stagger: 30,
    // nicer reveal transition
    visibleStyle: { transform: 'translateY(0)', opacity: 1 },
    hiddenStyle: { transform: 'translateY(100px)', opacity: 0 },
  });

  })
}