
/*-----------------------------------------
    button modal dynamic
-------------------------------------------*/

export function imgToModal(btn = ".imgToModal",target="imgfull") {

  var items = document.querySelectorAll(btn);
  var targ = document.getElementById(target);


  if (items && targ) {
    items.forEach((item,index) => {
      let targ_src = item.dataset.mcontent;
      item.onclick = function () {
        targ.src=targ_src;
      }
    }) 
  }

}