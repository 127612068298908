
/*-----------------------------------------
    Off Canvas Mobile Menu
-------------------------------------------*/

export function burgerMenu() {
    document.querySelector(".mobile-menu-hamburger").addEventListener("click", function (event) {
        document.body.classList.add('fix');
        document.querySelector(".mobile-menu-wrapper").classList.add('open');
    });

    document.querySelector(".offcanvas-btn-close,.offcanvas-overlay").addEventListener("click", function (event) {
        document.body.classList.remove('fix');
        document.querySelector(".mobile-menu-wrapper").classList.remove('open');
    });

    let twofact = document.getElementById("conect-two");
    if (twofact){
        twofact.querySelector('form').classList.add('m-0','h-100','position-relative','display-5','fw-bold','text-white','bg-blacktrans','p-4','rounded-4','mt-3')
        twofact.querySelector('h1').classList.add('m-0','h-100','position-relative','display-1','fw-bold','text-white','mb-3')
        twofact.querySelector('label').classList.add('me-3')
        twofact.querySelectorAll('td','th').forEach(function (elem) {elem.classList.add('p-1')})
        twofact.querySelectorAll('.btn').forEach(function (elem) {elem.classList.add( 'p-4','py-2','m-2','rounded-3')})
        twofact.querySelectorAll('.errorlist').forEach(function (elem) {elem.classList.add( 'display-6','fw-light','list-unstyled','text-warning')})
    }
}